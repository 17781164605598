<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModel"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <div class="loader_area" v-if="deliveryLogLoader"><quote-loader /></div>
                <div class="content_area">
                    <div class="section_content w-100">
                        <div class="actions_widgets mb-1">
                            <div class="left_sectn">
                                <div class="widgets_wpr">
                                    <div class="header pb-2">
                                        <h2>Delivery Statistics <span>Last 30 days</span></h2>
                                    </div>
                                    <vue-echarts :option="option" ref="chart"></vue-echarts>
                                </div>
                            </div>
                            <div class="right_sectn">
                                <ul class="card_list">
                                    <li>
                                        <div class="chart_card">
                                            <h3>{{ deliveryLog.deliveryRate }}</h3>
                                            <h6>Delivery Rate</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chart_card">
                                            <h3>{{ deliveryLog.openRate }}</h3>
                                            <h6>Opened Rate</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chart_card">
                                            <h3>{{ deliveryLog.clickRate }}</h3>
                                            <h6>Click Rate</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="section_content w-100">
                        <div class="section_header">
                            <h2>Schedules</h2>
                            <div class="profile_lifecycles p-0">
                                <div class="form_grp m-0 p-0">
                                    <div class="group_item">
                                        <date-filter v-model="dateParams" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="result_wpr new">
                            <div class="actions mt-1">
                                <ul>
                                    <li class="optionDrops contacts-tabs" @click="featureList = !featureList" v-click-outside="closeFeatureFilter">
                                        {{ params.feature }}<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="featureList ? 'active' : ''">
                                            <ul>
                                                <li v-for="(feature, f) in featureArray" :key="f" @click="params.feature = feature">{{ feature }}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops contacts-tabs" @click="statusList = !statusList" v-click-outside="closeStatusFilter">
                                        {{ params.status }}<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="statusList ? 'active' : ''">
                                            <ul>
                                                <li  v-for="(status, s) in statusArray" :key="s" @click="params.status = status">{{ status }}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="search_area ml-auto" :class="{'active': searchField}">
                                        <input type="text" @input="isTyping = true" v-model.trim="params.search" placeholder="Search" />
                                        <button class="search_btn" @click="searchField = !searchField;">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageFilter">
                                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="per-page-filter" />
                                    </li>
                                </ul>
                            </div>
                            <div v-if="emailReportLoader"><line-loader /></div>
                            <div class="scroll_table">
                                <table class="show_header" v-click-outside="closeEmailFilter">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Date</th>
                                            <th>Feature</th>
                                            <th>Subject</th>
                                            <th>Status</th>
                                            <th>Delivery</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody  v-if="emailReports.data && emailReports.data.length">
                                        <tr v-for="(emailReport, e) in emailReports.data" :key="e">
                                            <td>
                                                <div class="user_wpr">
                                                    <div class="user_img">
                                                        <img :src="emailReport.photo ? emailReport.photo : require('@/assets/images/table-user.png')" alt="">
                                                    </div>
                                                    <h4>{{ getContactName(emailReport.recipient) }}</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="email_wpr">
                                                    <span class="email_add">{{ getContactEmail(emailReport.recipient) }}</span>
                                                </div>
                                            </td>
                                            <td>{{ moment(emailReport.created_at).format('ll | LT') }}</td>
                                            <td>{{ emailReport.source}}</td>
                                            <td>{{ emailReport.subject}}</td>
                                            <td>
                                                <button :class="{'success': emailReport.event == 'delivered', 'failed': emailReport.event == 'failed'}">{{ emailReport.event}}</button>
                                            </td>
                                            <td>{{ emailReport.status_message}}</td>
                                            <td>
                                                <div class="dropdown px-1" :class="{ active : dropdown == `dropdown${e}` }" @click="toggleEmailDropdown(e)">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                    <div class="drp_wrapper">
                                                        <ul>
                                                            <li @click="handleResendEmail(emailReport)">Resend</li>
                                                            <li @click="handleEditEmail(emailReport)">Edit &amp; Resend</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="8" class="px-4 text-center">
                                                <div class="empty_box">
                                                    <img src="@/assets/images/empty_state.svg">
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="table_footer">
                            <ul>
                                <li>{{ emailReports.from ? emailReports.from : 0 }} - {{ emailReports.to ? emailReports.to : 0 }} of {{ emailReports.total }}</li>
                            </ul>
                        </div>
                        <div class="pagination" v-show="emailReports.total">
                            <pagination :range-size="0" v-model="params.page" :pages="emailReports.last_page" @update:modelValue="handlePagination" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" v-if="resendEmail" :class="{active : resendEmail}">
                <div class="modal_container">
                    <div class="modal_header border-bottom">
                        <div class="modal_info">
                            <h1>Edit &amp; Resend</h1>
                            <div class="user_info">
                                <img :src="recipientPhoto ? recipientPhoto : require('@/assets/images/table-user.png')" alt="">
                                <div>
                                    <h5>{{ recipientName }}</h5>
                                    <h6>{{ recipientEmail }}</h6>
                                </div>
                            </div>
                        </div>
                        <button class="close_btn left_out" @click="resendEmail = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div class="modal_body">
                        <Form @submit="handleSubmit" v-slot="{ errors }" ref="resend-email-form" class="resend-email-form">
                            <div class="setting_wpr">
                                <email-component-local v-model="emailContent" :contacts="contactId" :errors="errors" :success-handler="closeResendModal" :handle-default-email="handleDefaultEmail" ref="email-component" />
                            </div>
                        </Form>
                    </div>
                    <div class="modal_footer">
                        <button :disabled="emailLoader" type="button" class="btn cancel_btn" @click="resendEmail = false">Cancel</button>
                        <button :disabled="emailLoader" type="button" class="btn save_btn" @click="handleSubmit()"><i class="fa fa-spinner fa-spin" v-if="emailLoader"></i> {{ emailLoader ? 'Sending' : 'Send' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { VueEcharts } from 'vue3-echarts'
    import { Form} from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const DateFilter = defineAsyncComponent(() => import('@/components/DateFilter'))
    const EmailComponentLocal = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))

    import moment from 'moment'

    export default {
        name: 'Email Reports',

        data () {
            return{
                featureList: false,
                statusList: false,
                resendEmail: false,
                searchField: false,
                rowInfo:{
                    name: '',
                    email: '',
                },
                option : {
                    color: ['#74d214', '#2F7FED', '#f2a31e', '#4A4E53', '#c02428', '#ef5b6d'],
                    legend: {
                        data: ['Delivered', 'Opened', 'Clicked', 'Unsubscribed', 'Complained', 'Failed'],
                        right: 0,
                        // icon: "circle",
                    },
                    tooltip: {
                        trigger: 'axis',
                        // formatter: function (params) {
                        //     return params[0].name + ': ' + params[0].value;
                        // }
                    },
                    grid: {
                        top: 60,
                        left: 25,
                        right: '1%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        offset: 10,
                        axisTick: {
                            length: 4,
                            lineStyle:{
                                type: "solid",
                                color: "#2F7FED",
                                cap: "round",
                                width: 4
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: "#E8E7E7"
                            }
                        },
                        axisLabel: {
                            color: '#4D4D4D'
                        },
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value',
                        offset: 15,
                        minInterval: 1,
                        min: 0,
                        max: (value) => value.max < 5 ? 5 : value.max,
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            // symbol: 'none',
                            name: 'Delivered',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(242,163,30,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,203,117,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#74d214'
                                        },
                                        {
                                            offset: 1,
                                            color: '#74d214'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                        {
                            // symbol: 'none',
                            name: 'Opened',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(242,163,30,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,203,117,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#2F7FED'
                                        },
                                        {
                                            offset: 1,
                                            color: '#88CBEE'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                        {
                            // symbol: 'none',
                            name: 'Clicked',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(0,0,0,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#f2a31e'
                                        },
                                        {
                                            offset: 1,
                                            color: '#ffcb75'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                        {
                            // symbol: 'none',
                            name: 'Unsubscribed',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(0,0,0,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#4A4E53'
                                        },
                                        {
                                            offset: 1,
                                            color: '#5a5a5a'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                        {
                            // symbol: 'none',
                            name: 'Complained',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(0,0,0,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#c02428'
                                        },
                                        {
                                            offset: 1,
                                            color: '#c02428'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        },
                        {
                            // symbol: 'none',
                            name: 'Failed',
                            data: [0, 0, 0, 0, 0, 0, 0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(0,0,0,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#ef5b6d'
                                        },
                                        {
                                            offset: 1,
                                            color: '#ef5b6d'
                                        }
                                    ],
                                    global: false
                                }
                            }
                        }
                    ],
                    dataZoom: [{
                        start: 78,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 78,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 90,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                grid: {
                                    top: 90,
                                },
                                dataZoom: [{
                                    start: 93,
                                    end: 100,
                                }],
                            }
                        },
                    ]
                },
                dropdown: '',
                moment,
                dateParams: {
                    duration: 'Last 7 Days',
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                },
                emailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                featureArray: [
                    'All',
                    'Sequences',
                    'Template',
                    'Email',
                    'Page',
                    'Form',
                    'Broadcast',
                    'Journey',
                    'Playbook',
                ],
                statusArray: [
                    'All',
                    'Delivered',
                    'Opened',
                    'Unopened',
                    'Clicked',
                    'Unsubscribed',
                    'Complained',
                    'Failed',
                ],
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    feature: 'All',
                    status: 'All',
                    order: 'desc',
                    type: 'leads',
                    unopen: '',
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                },
                recipientName: '',
                recipientEmail: '',
                recipientPhoto: '',
                contactId : '',
                isTyping: true,
            }
        },

        props: {
            modelValue: Boolean,
        },

        watch: {
            'dateParams.start_date' () {
                const vm = this;

                vm.params.start_date = vm.dateParams.start_date;
                vm.params.end_date   = vm.dateParams.end_date;
                vm.params.page       = 1;
                vm.getEmailReport(vm.params);
            },

            deliveryLog () {
                const vm = this;

                vm.resetChart();
            },

            modelValue (value) {
                if (value) {
                    const vm = this;

                    setTimeout(function () {
                        vm.getDeliveryLog();
                        vm.getSignature();
                    }, 10);

                    setTimeout(function () {
                        vm.getEmailReport(vm.params);
                    }, 2000);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getEmailReport(vm.params);
            },

            'params.feature' () {
                const vm  = this;

                vm.params.page = 1;
                vm.params.search = '';
                vm.getEmailReport(vm.params)
            },

            'params.status' () {
                const vm  = this;

                vm.params.page = 1;
                vm.params.search = '';
                vm.getEmailReport(vm.params)
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;

                            vm.getEmailReport(vm.params);
                        }
                    }
                }
            },
        },

        components: {
            EmailComponentLocal,
            VueEcharts,
            DateFilter,
            Form,
        },

        computed: {
            ...mapState ({
                emailReports: state => state.emailLogModule.emailReports,
                emailReportLoader: state => state.emailLogModule.emailReportLoader,
                emailLoader: state => state.contactModule.emailLoader,
                deliveryLog: state => state.emailLogModule.deliveryLog,
                defaultSignature: state => state.signatureModule.defaultSignature,
                deliveryLogLoader: state => state.emailLogModule.deliveryLogLoader,
            }),
        },

        methods:{
            ...mapActions ({
                getEmailReport: 'emailLogModule/getEmailReport',
                sendEmail: 'contactModule/sendEmail',
                getDeliveryLog: 'emailLogModule/getDeliveryLog',
                getSignature: 'signatureModule/getSignature',
            }),

            closeModel(){
                const vm = this;

                vm.resendEmail = false;
                vm.$emit('update:modelValue', false)
            },

            handleDefaultEmail () {
                const vm = this;
                vm.emailContent  = {
                    defaultEmail: true,
                    subject: 'Here is your member ID {{first_name}}',
                    email: `<p>Hi {{first_name}} here is your unique member ID for {{company_name}}.</p>
                    <p>You can use this ID to submit any document, form, referrals, review, or progress report and it will be tracked to your member account.</p>
                    <p><br>Please write this down somewhere you wont't forget it.</p>
                    <p>Client Name: {{full_name}}</p>
                    <p>Client ID: {{member_id}}<br><br></p>
                    <p>Cheers!</p>
                    <p>Thrive Coach</p>`,
                }
            },

            getContactName (string) {
                let arr = string.split('<');
                return arr[0];
            },

            getContactEmail (string) {
                let arr = string.split('<');
                let email = arr[1].replace(">", "");
                return email;
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeFeatureFilter () {
                const vm = this;

                vm.featureList = false;
            },

            closeStatusFilter () {
                const vm = this;

                vm.statusList = false;
            },

            closeEmailFilter () {
                const vm = this;

                vm.dropdown = false;
            },

            handlePagination (page) {
              const vm = this;

              vm.params.page = page;
              vm.getEmailReport(vm.params);
            },

            toggleEmailDropdown (val) {
                const vm  = this;
                if (vm.dropdown == `dropdown${val}`) {
                    vm.dropdown = '';
                } else {
                    vm.dropdown = `dropdown${val}`;
                }
            },

            handleEditEmail (emailReport) {
                const vm = this;
                const recipient = emailReport.recipient.split('<');

                vm.recipientName  = recipient[0];
                vm.recipientEmail = recipient[1].replace(">", "");
                vm.recipientPhoto = emailReport.photo;
                vm.contactId      = emailReport.recipient_id;
                vm.emailContent   = {
                    defaultHandler: true,
                    source: 'contacts',
                    subject: emailReport.subject,
                    email: emailReport.body,
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    contact_id: emailReport.recipient_id,
                },
                vm.resendEmail = true;
            },

            handleResendEmail (emailReport) {
                const vm = this;
                const recipient = emailReport.recipient.split('<');

                vm.emailContent = {
                    source: 'contacts',
                    subject: emailReport.subject,
                    email: emailReport.body,
                    is_email_conf: 0,
                    is_email_logo: 1,
                    is_signature: 1,
                    signature_id: vm.defaultSignature,
                    show_email_logo: 1,
                    email_logo: '',
                    contact_id: emailReport.recipient_id,
                }

                vm.sendEmail(vm.emailContent)
            },

            handleSubmit () {
                const vm = this;
                vm.emailContent.contact_id = vm.contactId;

                const emailForm = vm.$refs['resend-email-form'];

                emailForm.validate().then((result) => {
                    if (result.valid) {
                        vm.sendEmail(vm.emailContent).then(() => {
                            vm.resendEmail = false;
                        })
                    }
                })
            },

            resetChart() {
                const vm = this;

                vm.option.xAxis.data     = vm.deliveryLog.axis;
                vm.option.series[0].data = vm.deliveryLog.deliveredData;
                vm.option.series[1].data = vm.deliveryLog.openedData;
                vm.option.series[2].data = vm.deliveryLog.clickedData;
                vm.option.series[3].data = vm.deliveryLog.unsubscribedData;
                vm.option.series[4].data = vm.deliveryLog.complainedData;
                vm.option.series[5].data = vm.deliveryLog.failedData;

                if (vm.$refs.chart) {
                    vm.$refs.chart.refreshChart();
                }
            },

            closeResendModal () {
                const vm = this;
                vm.resendEmail = false;
            }
        }
    }
</script>

<style scoped>
    .content_area{
        max-width: 1260px;
        margin: 0 auto;
    }
    .result_wpr.new .actions > ul{
        width: 100%;
    }
    .profile_lifecycles :deep(.field_wpr){
        border: 0;
        background: transparent;
    }
    .actions_widgets .left_sectn {
        flex: 1 1 auto;
    }
    :deep(.vue-echarts){
        height: 450px;
    }
    .actions_widgets .right_sectn {
        flex: 0 0 250px;
        min-width: 250px;
    }
    .card_list{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .card_list li{
        flex: 1;
    }
    .card_list li:not(:last-child){
        padding-bottom: 30px;
    }
    .card_list .chart_card {
        padding: 20px 25px;
        height: 100%;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #fff;
    }
    .card_list .chart_card h3{
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
    }
    .card_list li:nth-child(1) .chart_card h3{
        color: #74d214;
    }
    .card_list li:nth-child(2) .chart_card h3{
        color: #2F7FED;
    }
    .card_list li:nth-child(3) .chart_card h3{
        color: #f2a31e;
    }
    .card_list .chart_card h6{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 5px;
    }
    table th:first-child{
        width: auto;
        padding: 10px 18px;
        text-align: left;
    }
    table td:first-child{
        padding: 18px;
        width: auto;
        text-align: left;
    }
    .result_wpr table td .user_img{
        margin-right: 10px;
    }
    table button{
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }
    table button.success{
        background: #00aa14;
    }
    table button.failed{
        background: #f04438;
    }
    table .dropdown .drp_wrapper{
        display: none;
        text-align: left;
    }
    table .dropdown.active .drp_wrapper{
        display: block;
    }
    .profile_lifecycles .form_grp :deep(.multiselect .multiselect-dropdown){
        left: auto;
        right: 1px;
    }
    .resend-email-form :deep(.setting_wpr .group_item){
        width: 100%;
    } 
    @media(max-width:1199px){
        .actions_widgets .right_sectn{
            flex: 0 0 100%;
        }
        .right_sectn .card_list{
            flex-direction: row;
            gap: 20px;
        }
        .right_sectn .card_list li{
            padding: 0;
        }
        .card_list .chart_card h3{
            font-size: 22px;
            line-height: 30px;
        }
        .result_wpr table{
            min-width: 890px !important;
        }
        /* .result_wpr table, .result_wpr .actions > ul {
            flex-wrap: inherit !important;
        }
        .result_wpr table, .result_wpr .actions > ul > li.optionDrops{
            flex: 1 1 100px !important;
            border-radius: inherit !important;
            order: unset !important;
            border-bottom: inherit !important;
        } */
    }
    @media(max-width: 991px){
        .global_setting .tabs_content .section_content{
            padding: 0 0 30px 0;
        }
    }
    @media(max-width: 768px){
        .card_list .chart_card{
            padding: 20px;
        }
        .card_list .chart_card h6{
            font-size: 13px;
            line-height: 17px;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            order: -1;
        }
        .result_wpr.new .actions > ul li.sort_list{
            margin-left: auto;
        }
    }
    @media(max-width: 499px){
        .right_sectn .card_list {
            flex-wrap: wrap;
        }
        .right_sectn .card_list li{
            flex: 0 0 calc(50% - 10px);
        }
        .profile_lifecycles .form_grp :deep(.multiselect){
            min-height: 25px;
        }
    }
</style>
